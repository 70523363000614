(function () {
	var columnHeight = {
		init: function () {
			var _this = this;

			_this.height($(".reviews__col"));
			_this.height($(".cafe-unit .unit-col"));
			_this.height($(".present-block_col-3 .present-block__data"));
		},
		height: function (columns) {
			var tallestcolumn = 0;
			columns.each(
				function () {
					var currentHeight = $(this).height();
					if (currentHeight > tallestcolumn) {
						tallestcolumn = currentHeight;
					}
				}
			);
			columns.height(tallestcolumn);
		}
	};

	var getmore = {
		init: function () {
			var $this = $('.js--get-more');
			
			$this.each(function(){
				var $parent = $(this),
					$link = $parent.find('.js--get-more__link');

				var textDefault = $link.data('getmore-default'),
					textOpened = $link.data('getmore-opened');
				
				var toggleContent = function() {
					$parent.toggleClass('opened');

					if($parent.is('.opened')) {
						if(textOpened !== undefined) $link.text(textOpened);
					} else {
						if(textDefault !== undefined) $link.text(textDefault);
					}
				};

				$link.on('click', function () {
					toggleContent();
					return false;
				});
			});
		}
	};
	
	getmore.init();
	columnHeight.init();
}());

$(document).ready(function () {

	//меню для мобилок
	$(".header-menu-btn").click(function () {
		$(this).toggleClass("closed");
	});

	$(".menu-btn").click(function () {
		$(this).toggleClass("closed");
		$('.header__nav').toggleClass('active');
		$('.off-canvas').toggleClass('active');
	});

	$('[data-toggle=tooltip]').tooltip({
		animation: false,
		container: 'body'
	});
	
	$('[data-toggle=dropdown]').dropdown();

	/**
	 * прокрутка до якоря
	 */

	$(".anchor").click(function () {
		$("html, body").animate({
			scrollTop: $($(this).attr("href")).offset().top - 68 + "px"
		}, {
			duration: 1500,
			easing: "swing"
		});
		return false;
	});
	
	/**
	 * прокрутка до якоря в сервайсах
	 */
	var services = {
		d: 50,
		h: location.hash,
		
		init: function() {
			var o = this,
				s = o.h.substring(1);

			if(s !== '') {
				var $e = $(o.h);
				var top = $e.offset().top - o.d;
				
				$e.addClass('opened');
				$('html, body').animate( { scrollTop: top }, 600 );
			}
		}
	};
	services.init();

	/**
	 * select
	 */

	function initSelect() {
		$('.select-style__sel').each(function () {
			$(this).siblings('.select-style__text').text($(this).children('option:selected').text());
		});
		$('.select-style__sel').unbind('change').change(function () {
			$(this).siblings('.select-style__text').text($(this).children('option:selected').text());
		});
	}

	initSelect();

	/**
	 * Плагин слайдера
	 */
	$('.js--slider').each(function (index, element) {
		var $this = $(this);
		$this.find('.swiper-container').addClass('s' + index);
		$this.find('.swiper-button-next').addClass('r' + index);
		$this.find('.swiper-button-prev').addClass('l' + index);
		
		var slider = new Swiper('.s' + index, {
			slidesPerView: $this.is('.js--slider_05') ? 5 : 1,
			nextButton: '.r' + index,
			prevButton: '.l' + index,
			loop: true,
			speed: 1500
		});
		
		/*
		$('.slider-commands__button-prev').on('click', function(e){
			e.preventDefault()
			var swiper = $this.find('.swiper-container').data('swiper');
			swiper.swipePrev();
		});
		$('.slider-commands__button-next').on('click', function(e){
			e.preventDefault()
			var swiper = $this.find('.swiper-container').data('swiper');
			swiper.swipeNext();
		});
		*/
	});
	
	/**
	 * Сетка проектов и каталога
	 */

	if ($(window).width() > 767) {

		var $container = $('.grid');

		$container.imagesLoaded(function () {
			$container.masonry({
				itemSelector: '.grid-item',
				columnWidth: '.grid-item',
				//isFitWidth: true,
				percentPosition: true,
				cornerStampSelector: '.corner-stamp'
			});
		});
	}

	/**
	 * catalog menu
	 */
/*
	$('.vertical-menu__item').click(function(){
		$(this).toggleClass('active');
	});*/

	/**
	 * colorbox
	 */

	callbackForm = $('#callback');
	$('.callback-link').colorbox({
		reposition: true,
		fixed: true,
		inline:true,
		maxWidth:'97%',
		maxHeight:'97%',
		current: false,
		previous: false,
		next: false,
		close:'<i class="icon icon-cancel"></i>',
	});

	if ($(window).width() > 767) {
		$('.colorbox').colorbox({
			reposition: true,
			fixed: true,
			rel:'gal',
			maxWidth:'95%',
			maxHeight:'95%',
			current: '',
			previous:'<i class="icon icon-left-open"></i>',
			next:'<i class="icon icon-right-open"></i>',
			close:'<i class="icon icon-cancel"></i>',
		});
	} else {
		$('.colorbox').click(function(){
			return false;
		});
	}

});